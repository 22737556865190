import { E_FETCH_STATUS } from "../../constant";
import { ColourData, ColourFilterType } from "../../interfaces/colour";
import {
  ColourAction,
  ColourSetCurrentPage,
  ColourSetFilter,
  ColourSetList,
  ColourSetStatusLoading,
  ColourSetTotalPage,
  ColourType,
} from "../types/colour";

interface ColourState {
  colourList: ColourData[];
  filterColour: ColourFilterType;
  statusLoading: E_FETCH_STATUS;
  currentPage: number;
  totalPage: number;
}

const initialState: ColourState = {
  colourList: [],
  filterColour: {
    filterFinishing: {
      label: "All Finishing",
      value: 0,
    },
    filterColour: {
      label: "All Colour",
      value: 0,
    },
  },
  statusLoading: E_FETCH_STATUS.INITIATE,
  currentPage: 1,
  totalPage: 0,
};

const {
  COLOUR_SET_LIST,
  COLOUR_SET_FILTER,
  COLOUR_SET_STATUS_LOADING,
  COLOUR_SET_CURRENT_PAGE,
  COLOUR_SET_TOTAL_PAGE,
} = ColourType;

const colour = (
  state: ColourState = initialState,
  action: ColourAction
): ColourState => {
  const { type } = action;

  const setColourList = (): ColourState => {
    const { payload: colourList } = action as ColourSetList;
    return {
      ...state,
      colourList,
    };
  };

  const setColourFilter = (): ColourState => {
    const { payload: filterColour } = action as ColourSetFilter;
    return {
      ...state,
      filterColour,
    };
  };

  const setColourStatusLoading = (): ColourState => {
    const { payload: statusLoading } = action as ColourSetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setColourCurrentPage = (): ColourState => {
    const { payload: currentPage } = action as ColourSetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setColourTotalPage = (): ColourState => {
    const { payload: totalPage } = action as ColourSetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  switch (type) {
    case COLOUR_SET_LIST:
      return setColourList();
    case COLOUR_SET_FILTER:
      return setColourFilter();
    case COLOUR_SET_STATUS_LOADING:
      return setColourStatusLoading();
    case COLOUR_SET_CURRENT_PAGE:
      return setColourCurrentPage();
    case COLOUR_SET_TOTAL_PAGE:
      return setColourTotalPage();
    default:
      return state;
  }
};

export default colour;
