import { ZipCodeData } from "../../interfaces/util";

export enum DaerahType {
  DAERAH_SET_PROVINSI = "DAERAH_SET_PROVINSI",
  DAERAH_SET_KABUPATEN = "DAERAH_SET_KABUPATEN",
  DAERAH_SET_KELURAHAN = "DAERAH_SET_KELURAHAN",
  DAERAH_SET_KECAMATAN = "DAERAH_SET_KECAMATAN",
  DAERAH_SET_ZIPCODE = "DAERAH_SET_ZIPCODE",
}

export interface DaerahSetProvinsi {
  type: DaerahType.DAERAH_SET_PROVINSI;
  payload: string[];
}

export interface DaerahSetKabupaten {
  type: DaerahType.DAERAH_SET_KABUPATEN;
  payload: string[];
}

export interface DaerahSetKelurahan {
  type: DaerahType.DAERAH_SET_KELURAHAN;
  payload: string[];
}

export interface DaerahSetKecamatan {
  type: DaerahType.DAERAH_SET_KECAMATAN;
  payload: string[];
}

export interface DaerahSetZipcode {
  type: DaerahType.DAERAH_SET_ZIPCODE;
  payload: ZipCodeData;
}

export type DaerahAction =
  | DaerahSetProvinsi
  | DaerahSetKabupaten
  | DaerahSetKecamatan
  | DaerahSetKelurahan
  | DaerahSetZipcode;
