import { E_FETCH_STATUS } from "../../constant";
import {
  ShopData,
  ShopFilterData,
  ShopSelectData,
} from "../../interfaces/shop";
import {
  ShopAction,
  ShopSetBranches,
  ShopSetCurrentPage,
  ShopSetFilter,
  ShopSetList,
  ShopSetSelect,
  ShopSetStatusLoading,
  ShopSetTotalPage,
  ShopType,
} from "../types/shop";

interface ShopState {
  shops: ShopData[];
  filterShop: ShopFilterData;
  shopSelect: ShopSelectData[];
  branches: ShopData[];
  statusLoading: E_FETCH_STATUS;
  currentPage: number;
  totalPage: number;
}

const initialState: ShopState = {
  shops: [],
  filterShop: {
    filterStoreName: {
      label: "All Store",
      value: 0,
    },
    filterStoreOwnerName: "",
    filterStoreNPWP: "",
  },
  shopSelect: [],
  branches: [],
  statusLoading: E_FETCH_STATUS.INITIATE,
  currentPage: 1,
  totalPage: 0,
};

const {
  SHOP_SET_LIST,
  SHOP_SET_FILTER,
  SHOP_SET_SELECT,
  SHOP_SET_STATUS_LOADING,
  SHOP_SET_CURRENT_PAGE,
  SHOP_SET_TOTAL_PAGE,
  SHOP_SET_BRANCHES,
} = ShopType;

const shop = (
  state: ShopState = initialState,
  action: ShopAction
): ShopState => {
  const { type } = action;

  const setShops = (): ShopState => {
    const { payload: shops } = action as ShopSetList;
    return {
      ...state,
      shops,
    };
  };

  const setShopFilter = (): ShopState => {
    const { payload: filterShop } = action as ShopSetFilter;
    return {
      ...state,
      filterShop,
    };
  };

  const setShopStatusLoading = (): ShopState => {
    const { payload: statusLoading } = action as ShopSetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setShopCurrentPage = (): ShopState => {
    const { payload: currentPage } = action as ShopSetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setShopTotalPage = (): ShopState => {
    const { payload: totalPage } = action as ShopSetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  const setBranches = (): ShopState => {
    const { payload: branches } = action as ShopSetBranches;
    return {
      ...state,
      branches,
    };
  };

  const setShopSelect = (): ShopState => {
    const { payload: shops } = action as ShopSetSelect;
    return {
      ...state,
      shopSelect: shops,
    };
  };

  switch (type) {
    case SHOP_SET_LIST:
      return setShops();
    case SHOP_SET_FILTER:
      return setShopFilter();
    case SHOP_SET_STATUS_LOADING:
      return setShopStatusLoading();
    case SHOP_SET_CURRENT_PAGE:
      return setShopCurrentPage();
    case SHOP_SET_TOTAL_PAGE:
      return setShopTotalPage();
    case SHOP_SET_BRANCHES:
      return setBranches();
    case SHOP_SET_SELECT:
      return setShopSelect();
    default:
      return state;
  }
};

export default shop;
