import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { ComissionCategoryData } from "../../interfaces/comissioncategory";

export enum ComissionCategoryType {
  COMISSION_CATEGORY_SET_LIST = "COMISSION_CATEGORY_SET_LIST",
  COMISSION_CATEGORY_SET_STATUS_LOADING = "COMISSION_CATEGORY_SET_STATUS_LOADING",
  COMISSION_CATEGORY_SET_FILTER = "COMISSION_CATEGORY_SET_FILTER",
  COMISSION_CATEGORY_SET_CURRENT_PAGE = "COMISSION_CATEGORY_SET_CURRENT_PAGE",
  COMISSION_CATEGORY_SET_TOTAL_PAGE = " COMISSION_CATEGORY_SET_TOTAL_PAGE",
}

export interface ComissionCategorySetList {
  type: ComissionCategoryType.COMISSION_CATEGORY_SET_LIST;
  payload: ComissionCategoryData[];
}

export interface ComissionCategorySetStatusLoading {
  type: ComissionCategoryType.COMISSION_CATEGORY_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface ComissionCategorySetFilter {
  type: ComissionCategoryType.COMISSION_CATEGORY_SET_FILTER;
  payload: DefaultSelection;
}

export interface ComissionCategorySetCurrentPage {
  type: ComissionCategoryType.COMISSION_CATEGORY_SET_CURRENT_PAGE;
  payload: number;
}

export interface ComissionCategorySetTotalPage {
  type: ComissionCategoryType.COMISSION_CATEGORY_SET_TOTAL_PAGE;
  payload: number;
}

export type ComissionCategoryAction =
  | ComissionCategorySetList
  | ComissionCategorySetStatusLoading
  | ComissionCategorySetFilter
  | ComissionCategorySetCurrentPage
  | ComissionCategorySetTotalPage;
