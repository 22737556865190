import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { FinishingData } from "../../interfaces/finishing";
export enum FinishingType {
  FINISHING_SET_LIST = "FINISHING_SET_LIST",
  FINISHING_SET_STATUS_LOADING = "FINISHING_SET_STATUS_LOADING",
  FINISHING_SET_FILTER = "FINISHING_SET_FILTER",
}

export interface FinishingSetList {
  type: FinishingType.FINISHING_SET_LIST;
  payload: FinishingData[];
}

export interface FinishingSetStatusLoading {
  type: FinishingType.FINISHING_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface FinishingSetFilter {
  type: FinishingType.FINISHING_SET_FILTER;
  payload: DefaultSelection;
}

export type FinishingAction =
  | FinishingSetList
  | FinishingSetStatusLoading
  | FinishingSetFilter;
