import { E_FETCH_STATUS } from "../../constant";
import { PackageData } from "../../interfaces/package";

export enum PackageType {
  PACKAGE_SET_LIST = "PACKAGE_SET_LIST",
  PACKAGE_SET_STATUS_LOADING = "PACKAGE_SET_STATUS_LOADING",
  PACKAGE_SET_CURRENT_PAGE = "PACKAGE_SET_CURRENT_PAGE",
  PACKAGE_SET_TOTAL_PAGE = "PACKAGE_SET_TOTAL_PAGE",
}

export interface PackageSetList {
  type: PackageType.PACKAGE_SET_LIST;
  payload: PackageData[];
}

export interface PackageSetStatusLoading {
  type: PackageType.PACKAGE_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface PackageSetCurrentPage {
  type: PackageType.PACKAGE_SET_CURRENT_PAGE;
  payload: number;
}

export interface PackageSetTotalPage {
  type: PackageType.PACKAGE_SET_TOTAL_PAGE;
  payload: number;
}

export type PackageAction =
  | PackageSetList
  | PackageSetStatusLoading
  | PackageSetCurrentPage
  | PackageSetTotalPage;
