import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { ComissionCategoryData } from "../../interfaces/comissioncategory";
import {
  ComissionCategoryAction,
  ComissionCategorySetCurrentPage,
  ComissionCategorySetFilter,
  ComissionCategorySetList,
  ComissionCategorySetStatusLoading,
  ComissionCategorySetTotalPage,
  ComissionCategoryType,
} from "../types/comissionCategory";

interface ComissionCategoryState {
  filterComissionCategory: DefaultSelection;
  statusLoading: E_FETCH_STATUS;
  comissionCategoryList: ComissionCategoryData[];
  currentPage: number;
  totalPage: number;
}

const initialState: ComissionCategoryState = {
  filterComissionCategory: { label: "All Comission Category", value: 0 },
  statusLoading: E_FETCH_STATUS.INITIATE,
  comissionCategoryList: [],
  currentPage: 1,
  totalPage: 0,
};

const {
  COMISSION_CATEGORY_SET_LIST,
  COMISSION_CATEGORY_SET_STATUS_LOADING,
  COMISSION_CATEGORY_SET_FILTER,
  COMISSION_CATEGORY_SET_CURRENT_PAGE,
  COMISSION_CATEGORY_SET_TOTAL_PAGE,
} = ComissionCategoryType;

const comissionCategory = (
  state: ComissionCategoryState = initialState,
  action: ComissionCategoryAction
): ComissionCategoryState => {
  const { type } = action;

  const setComissionCategoryList = (): ComissionCategoryState => {
    const { payload: comissionCategoryList } =
      action as ComissionCategorySetList;
    return {
      ...state,
      comissionCategoryList,
    };
  };

  const setComissionCategoryStatusLoading = (): ComissionCategoryState => {
    const { payload: statusLoading } =
      action as ComissionCategorySetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setComissionCategoryFilter = (): ComissionCategoryState => {
    const { payload: filterComissionCategory } =
      action as ComissionCategorySetFilter;
    return {
      ...state,
      filterComissionCategory,
    };
  };

  const setComissionCategoryCurrentPage = (): ComissionCategoryState => {
    const { payload: currentPage } = action as ComissionCategorySetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setComissionCategoryTotalPage = (): ComissionCategoryState => {
    const { payload: totalPage } = action as ComissionCategorySetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  switch (type) {
    case COMISSION_CATEGORY_SET_LIST:
      return setComissionCategoryList();
    case COMISSION_CATEGORY_SET_STATUS_LOADING:
      return setComissionCategoryStatusLoading();
    case COMISSION_CATEGORY_SET_FILTER:
      return setComissionCategoryFilter();
    case COMISSION_CATEGORY_SET_CURRENT_PAGE:
      return setComissionCategoryCurrentPage();
    case COMISSION_CATEGORY_SET_TOTAL_PAGE:
      return setComissionCategoryTotalPage();
    default:
      return state;
  }
};

export default comissionCategory;
