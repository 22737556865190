import "./App.scss";
import SignIn from "./container/SignIn";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { getCookie } from "./utils/cookies";
import loadable from "@loadable/component";
import { E_AUTH_STATUS } from "./constant";

const Dashboard = loadable(() => import("./container/Dashboard"));
const Users = loadable(() => import("./container/Users"));
const Packages = loadable(() => import("./container/Packages"));
const Products = loadable(() => import("./container/Products"));
const ProductDetail = loadable(() => import("./container/ProductDetail"));
const Shops = loadable(() => import("./container/Shops"));
const ShopDetail = loadable(() => import("./container/ShopDetail"));
const UserDetail = loadable(() => import("./container/UserDetail"));
const Category = loadable(() => import("./container/Category"));
const Brand = loadable(() => import("./container/Brand"));
const Colour = loadable(() => import("./container/Colour"));
const FormSalesReport = loadable(() => import("./container/FormSalesReport"));
const SalesReportDetail = loadable(
  () => import("./container/SalesReportDetail")
);
const Comission = loadable(() => import("./container/Comission"));
const ComissionDetail = loadable(() => import("./container/ComissionDetail"));
const ComissionCategory = loadable(
  () => import("./container/ComissionCategory")
);
const ComissionCategoryDetail = loadable(
  () => import("./container/ComissionCategoryDetail")
);
const Finishing = loadable(() => import("./container/Finishing"));
const ListSalesReport = loadable(() => import("./container/ListSalesReport"));
const ListTarget = loadable(() => import("./container/ListTarget"));
const ComissionCGSPackage = loadable(
  () => import("./container/ComissionCGSPackage")
);
const ComissionCGSPackageDetail = loadable(
  () => import("./container/ComissionCGSPackageDetail")
);
const UserProfile = loadable(() => import("./container/UserProfile"));
const ReportData = loadable(() => import("./container/ReportData"));
const InputStockPage = loadable(() => import("./container/InputStockPage"));
const ListStockInputPage = loadable(
  () => import("./container/ListInputStockPage")
);
const ListStockPage = loadable(() => import("./container/ListStockPage"));

const ExcludeSingleComissionPage = loadable(
  () => import("./container/ExcludeSingleComission")
);
const userStatus = getCookie("userstatus");
const isSuperAdmin = Number(userStatus) === E_AUTH_STATUS.SUPERADMIN;
const isSupervisor = Number(userStatus) === E_AUTH_STATUS.SUPERVISOR;
const isSales = Number(userStatus) === E_AUTH_STATUS.SALES;
const isManager = Number(userStatus) === E_AUTH_STATUS.MANAGER;

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path={"/sign-in"}
          element={userStatus ? <Navigate to="/dashboard" /> : <SignIn />}
        />
        <Route
          path="/dashboard"
          element={userStatus ? <Dashboard /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/user"
          element={
            isSuperAdmin || isManager ? <Users /> : <Navigate to="/sign-in" />
          }
        />
        <Route
          path="/user/:idUser"
          element={
            isSuperAdmin || isManager ? (
              <UserDetail />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/packages"
          element={
            isSuperAdmin || isManager ? (
              <Packages />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/products"
          element={
            isSuperAdmin || isSupervisor || isManager ? (
              <Products />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/products/:idProduct"
          element={
            isSuperAdmin || isSupervisor || isManager ? (
              <ProductDetail />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/stores"
          element={
            isSuperAdmin || isSupervisor || isManager ? (
              <Shops />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/stores/:idShop"
          element={
            isSuperAdmin || isSupervisor || isManager ? (
              <ShopDetail />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/categories"
          element={
            isSuperAdmin || isSupervisor || isManager ? (
              <Category />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/brand"
          element={
            isSuperAdmin || isSupervisor || isManager ? (
              <Brand />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/finishing"
          element={
            isSuperAdmin || isSupervisor || isManager ? (
              <Finishing />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/colour"
          element={
            isSuperAdmin || isSupervisor || isManager ? (
              <Colour />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/create-sales-report"
          element={isSales ? <FormSalesReport /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/comission"
          element={
            isSuperAdmin || isSupervisor || isManager ? (
              <Comission />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/comission-detail/:idComission"
          element={
            isSuperAdmin || isManager || isSupervisor ? (
              <ComissionDetail />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/sales-report/:idSalesReport"
          element={
            userStatus ? <SalesReportDetail /> : <Navigate to="/sign-in" />
          }
        />
        <Route
          path="/comission-category"
          element={
            isSuperAdmin ? <ComissionCategory /> : <Navigate to="/sign-in" />
          }
        />
        <Route
          path="/comission-package-cgs"
          element={
            isSuperAdmin ? <ComissionCGSPackage /> : <Navigate to="/sign-in" />
          }
        />
        <Route
          path="/exclude-single-comission"
          element={
            isSuperAdmin ? (
              <ExcludeSingleComissionPage />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/comission-package-cgs/:idComissionCGS"
          element={
            isSuperAdmin ? (
              <ComissionCGSPackageDetail />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/comission-category-detail/:idComissionCategory"
          element={
            isSuperAdmin ? (
              <ComissionCategoryDetail />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/list-sales-report"
          element={isSales ? <ListSalesReport /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/list-target"
          element={isSales ? <ListTarget /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/"
          element={
            userStatus ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/profile"
          element={userStatus ? <UserProfile /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/generate-report-data"
          element={
            isSuperAdmin || isManager || isSupervisor ? (
              <ReportData />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/list-stock"
          element={userStatus ? <ListStockPage /> : <Navigate to="/sign-in" />}
        />

        <Route
          path="/list-stock-input"
          element={
            userStatus ? <ListStockInputPage /> : <Navigate to="/sign-in" />
          }
        />

        <Route
          path="/input-stock"
          element={userStatus ? <InputStockPage /> : <Navigate to="/sign-in" />}
        />
      </Routes>
    </Router>
  );
};

export default App;
