import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { ExcludeSingleComissionData } from "../../interfaces/excludeSingleComission";
import {
  ExcludeSingleComissionAction,
  ExcludeSingleComissionSetCurrentPage,
  ExcludeSingleComissionSetFilter,
  ExcludeSingleComissionSetList,
  ExcludeSingleComissionSetStatusLoading,
  ExcludeSingleComissionSetTotalPage,
  ExcludeSingleComissionType,
} from "../types/excludeSingleComission";

interface ExcludeSingleComissionState {
  filterExcludeSingleComission: DefaultSelection;
  statusLoading: E_FETCH_STATUS;
  excludeSingleComissionList: ExcludeSingleComissionData[];
  currentPage: number;
  totalPage: number;
}

const initialState: ExcludeSingleComissionState = {
  filterExcludeSingleComission: { label: "All Shop", value: 0 },
  statusLoading: E_FETCH_STATUS.INITIATE,
  excludeSingleComissionList: [],
  currentPage: 1,
  totalPage: 0,
};

const {
  EXCLUDE_SINGLE_COMISSION_SET_LIST,
  EXCLUDE_SINGLE_COMISSION_SET_STATUS_LOADING,
  EXCLUDE_SINGLE_COMISSION_SET_FILTER,
  EXCLUDE_SINGLE_COMISSION_SET_CURRENT_PAGE,
  EXCLUDE_SINGLE_COMISSION_SET_TOTAL_PAGE,
} = ExcludeSingleComissionType;

const excludeSingleComission = (
  state: ExcludeSingleComissionState = initialState,
  action: ExcludeSingleComissionAction
): ExcludeSingleComissionState => {
  const { type } = action;

  const setExcludeSingleComissionList = (): ExcludeSingleComissionState => {
    const { payload: excludeSingleComissionList } =
      action as ExcludeSingleComissionSetList;
    return {
      ...state,
      excludeSingleComissionList,
    };
  };

  const setExcludeSingleComissionStatusLoading =
    (): ExcludeSingleComissionState => {
      const { payload: statusLoading } =
        action as ExcludeSingleComissionSetStatusLoading;
      return {
        ...state,
        statusLoading,
      };
    };

  const setExcludeSingleComissionFilter = (): ExcludeSingleComissionState => {
    const { payload: filterExcludeSingleComission } =
      action as ExcludeSingleComissionSetFilter;
    return {
      ...state,
      filterExcludeSingleComission,
    };
  };

  const setExcludeSingleComissionCurrentPage =
    (): ExcludeSingleComissionState => {
      const { payload: currentPage } =
        action as ExcludeSingleComissionSetCurrentPage;
      return {
        ...state,
        currentPage,
      };
    };

  const setExcludeSingleComissionTotalPage =
    (): ExcludeSingleComissionState => {
      const { payload: totalPage } =
        action as ExcludeSingleComissionSetTotalPage;
      return {
        ...state,
        totalPage,
      };
    };

  switch (type) {
    case EXCLUDE_SINGLE_COMISSION_SET_LIST:
      return setExcludeSingleComissionList();
    case EXCLUDE_SINGLE_COMISSION_SET_STATUS_LOADING:
      return setExcludeSingleComissionStatusLoading();
    case EXCLUDE_SINGLE_COMISSION_SET_FILTER:
      return setExcludeSingleComissionFilter();
    case EXCLUDE_SINGLE_COMISSION_SET_CURRENT_PAGE:
      return setExcludeSingleComissionCurrentPage();
    case EXCLUDE_SINGLE_COMISSION_SET_TOTAL_PAGE:
      return setExcludeSingleComissionTotalPage();
    default:
      return state;
  }
};

export default excludeSingleComission;
