import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { BrandData } from "../../interfaces/brand";

export enum BrandType {
  BRAND_SET_LIST = "BRAND_SET_LIST",
  BRAND_SET_STATUS_LOADING = "BRAND_SET_STATUS_LOADING",
  BRAND_SET_FILTER = "BRAND_SET_FILTER",
}

export interface BrandSetList {
  type: BrandType.BRAND_SET_LIST;
  payload: BrandData[];
}

export interface BrandSetStatusLoading {
  type: BrandType.BRAND_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface BrandSetFilter {
  type: BrandType.BRAND_SET_FILTER;
  payload: DefaultSelection;
}

export type BrandAction = BrandSetList | BrandSetStatusLoading | BrandSetFilter;
