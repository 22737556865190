import { E_FETCH_STATUS, E_PROMO_TYPE } from "../../constant";
import {
  FilterStockInputInterface,
  StockInputData,
} from "../../interfaces/stock";
import {
  StockInputAction,
  StockInputSetCurrentPage,
  StockInputSetFilter,
  StockInputSetList,
  StockInputSetStatusLoading,
  StockInputSetTotalPage,
  StockInputType,
} from "../types/stockInput";

const maxDate: Date = new Date();
const minDate: Date = new Date(
  maxDate.getFullYear(),
  maxDate.getMonth(),
  maxDate.getDate() - 4
);

interface StockInputState {
  stockInputList: StockInputData[];
  statusLoading: E_FETCH_STATUS;
  currentPage: number;
  totalPage: number;
  filterStockInput: FilterStockInputInterface;
}

const initialState: StockInputState = {
  filterStockInput: {
    filterProductName: "",
    filterProductArticleNo: "",
    filterProductColour: {
      value: 0,
      label: "Select Colour",
    },
    filterProductCategory: {
      value: 0,
      label: "Select Category",
    },
    filterProductBrand: 0,
    filterProductPromoType: E_PROMO_TYPE.ALL,
    filterShop: {
      value: 0,
      label: "Select Shop",
    },
    filterStartDate: minDate,
    filterEndDate: maxDate,
  },
  stockInputList: [],
  statusLoading: E_FETCH_STATUS.INITIATE,
  currentPage: 1,
  totalPage: 0,
};

const {
  STOCK_INPUT_SET_LIST,
  STOCK_INPUT_SET_STATUS_LOADING,
  STOCK_INPUT_SET_CURRENT_PAGE,
  STOCK_INPUT_SET_TOTAL_PAGE,
  STOCK_INPUT_SET_FILTER,
} = StockInputType;

const stockInput = (
  state: StockInputState = initialState,
  action: StockInputAction
): StockInputState => {
  const { type } = action;

  const setStockInputList = (): StockInputState => {
    const { payload: stocksInput } = action as StockInputSetList;
    return {
      ...state,
      stockInputList: stocksInput,
    };
  };

  const setStockInputStatusLoading = (): StockInputState => {
    const { payload: statusLoading } = action as StockInputSetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setStockInputCurrentPage = (): StockInputState => {
    const { payload: currentPage } = action as StockInputSetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setStockInputTotalPage = (): StockInputState => {
    const { payload: totalPage } = action as StockInputSetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  const setStockInputFilter = (): StockInputState => {
    const { payload: filterStockInput } = action as StockInputSetFilter;
    return {
      ...state,
      filterStockInput,
    };
  };

  switch (type) {
    case STOCK_INPUT_SET_LIST:
      return setStockInputList();
    case STOCK_INPUT_SET_STATUS_LOADING:
      return setStockInputStatusLoading();
    case STOCK_INPUT_SET_CURRENT_PAGE:
      return setStockInputCurrentPage();
    case STOCK_INPUT_SET_TOTAL_PAGE:
      return setStockInputTotalPage();
    case STOCK_INPUT_SET_FILTER:
      return setStockInputFilter();
    default:
      return state;
  }
};

export default stockInput;
