import { E_FETCH_STATUS } from "../../constant";
import { UserData, UserFilterType } from "../../interfaces/user";

export enum UserType {
  USER_SET_LIST = "USER_SET_LIST",
  USER_SET_STATUS_LOADING = "USER_SET_STATUS_LOADING",
  USER_SET_CURRENT_PAGE = "USER_SET_CURRENT_PAGE",
  USER_SET_TOTAL_PAGE = "USER_SET_TOTAL_PAGE",
  USER_SET_AREA_SUPERVISOR_LIST = "USER_SET_AREA_SUPERVISOR_LIST",
  USER_SET_SUPERVISOR_LIST = "USER_SET_SUPERVISOR_LIST",
  USER_SET_MANAGER_LIST = "USER_SET_MANAGER_LIST",
  USER_SET_FILTER = "USER_SET_FILTER",
}

export interface UserSetList {
  type: UserType.USER_SET_LIST;
  payload: UserData[];
}

export interface UserSetStatusLoading {
  type: UserType.USER_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface UserSetCurrentPage {
  type: UserType.USER_SET_CURRENT_PAGE;
  payload: number;
}

export interface UserSetTotalPage {
  type: UserType.USER_SET_TOTAL_PAGE;
  payload: number;
}

export interface UserSetAreaSupervisorList {
  type: UserType.USER_SET_AREA_SUPERVISOR_LIST;
  payload: UserData[];
}

export interface UserSetSupervisorList {
  type: UserType.USER_SET_SUPERVISOR_LIST;
  payload: UserData[];
}

export interface UserSetManagerList {
  type: UserType.USER_SET_MANAGER_LIST;
  payload: UserData[];
}

export interface UserSetFilter {
  type: UserType.USER_SET_FILTER;
  payload: UserFilterType;
}

export type UserAction =
  | UserSetList
  | UserSetStatusLoading
  | UserSetCurrentPage
  | UserSetTotalPage
  | UserSetAreaSupervisorList
  | UserSetSupervisorList
  | UserSetManagerList
  | UserSetFilter;
