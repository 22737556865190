import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { ComissionCGSData } from "../../interfaces/comission";

export enum ComissionCGSType {
  COMISSION_CGS_SET_LIST = "COMISSION_CGS_SET_LIST",
  COMISSION_CGS_SET_STATUS_LOADING = "COMISSION_CGS_SET_STATUS_LOADING",
  COMISSION_CGS_SET_FILTER = "COMISSION_CGS_SET_FILTER",
  COMISSION_CGS_SET_CURRENT_PAGE = "COMISSION_CGS_SET_CURRENT_PAGE",
  COMISSION_CGS_SET_TOTAL_PAGE = " COMISSION_CGS_SET_TOTAL_PAGE",
}

export interface ComissionCGSSetList {
  type: ComissionCGSType.COMISSION_CGS_SET_LIST;
  payload: ComissionCGSData[];
}

export interface ComissionCGSSetStatusLoading {
  type: ComissionCGSType.COMISSION_CGS_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface ComissionCGSSetFilter {
  type: ComissionCGSType.COMISSION_CGS_SET_FILTER;
  payload: DefaultSelection;
}

export interface ComissionCGSSetCurrentPage {
  type: ComissionCGSType.COMISSION_CGS_SET_CURRENT_PAGE;
  payload: number;
}

export interface ComissionCGSSetTotalPage {
  type: ComissionCGSType.COMISSION_CGS_SET_TOTAL_PAGE;
  payload: number;
}

export type ComissionCGSAction =
  | ComissionCGSSetList
  | ComissionCGSSetStatusLoading
  | ComissionCGSSetFilter
  | ComissionCGSSetCurrentPage
  | ComissionCGSSetTotalPage;
