import { E_FETCH_STATUS } from "../../constant";
import {
  ShopData,
  ShopFilterData,
  ShopSelectData,
} from "../../interfaces/shop";

export enum ShopType {
  SHOP_SET_LIST = "SHOP_SET_LIST",
  SHOP_SET_FILTER = "SHOP_SET_FILTER",
  SHOP_SET_SELECT = "SHOP_SET_SELECT",
  SHOP_SET_STATUS_LOADING = "SHOP_SET_STATUS_LOADING",
  SHOP_SET_CURRENT_PAGE = "SHOP_SET_CURRENT_PAGE",
  SHOP_SET_TOTAL_PAGE = "SHOP_SET_TOTAL_PAGE",
  SHOP_SET_BRANCHES = "SHOP_SET_BRANCHES",
}

export interface ShopSetList {
  type: ShopType.SHOP_SET_LIST;
  payload: ShopData[];
}

export interface ShopSetFilter {
  type: ShopType.SHOP_SET_FILTER;
  payload: ShopFilterData;
}

export interface ShopSetSelect {
  type: ShopType.SHOP_SET_SELECT;
  payload: ShopSelectData[];
}

export interface ShopSetStatusLoading {
  type: ShopType.SHOP_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface ShopSetCurrentPage {
  type: ShopType.SHOP_SET_CURRENT_PAGE;
  payload: number;
}

export interface ShopSetTotalPage {
  type: ShopType.SHOP_SET_TOTAL_PAGE;
  payload: number;
}

export interface ShopSetBranches {
  type: ShopType.SHOP_SET_BRANCHES;
  payload: ShopData[];
}

export type ShopAction =
  | ShopSetList
  | ShopSetFilter
  | ShopSetStatusLoading
  | ShopSetCurrentPage
  | ShopSetTotalPage
  | ShopSetBranches
  | ShopSetSelect;
