import { E_FETCH_STATUS, E_PROMO_TYPE } from "../../constant";
import { FilterStockInterface, StockData } from "../../interfaces/stock";
import {
  StockAction,
  StockSetCurrentPage,
  StockSetFilter,
  StockSetList,
  StockSetStatusLoading,
  StockSetTotalPage,
  StockType,
} from "../types/stock";

interface StockState {
  stockList: StockData[];
  statusLoading: E_FETCH_STATUS;
  currentPage: number;
  totalPage: number;
  filterStock: FilterStockInterface;
}

const initialState: StockState = {
  filterStock: {
    filterProductName: "",
    filterProductArticleNo: "",
    filterProductColour: {
      value: 0,
      label: "Select Colour",
    },
    filterProductCategory: {
      value: 0,
      label: "Select Category",
    },
    filterProductBrand: 0,
    filterProductPromoType: E_PROMO_TYPE.ALL,
    filterShop: {
      value: 0,
      label: "Select Shop",
    },
  },
  stockList: [],
  statusLoading: E_FETCH_STATUS.INITIATE,
  currentPage: 1,
  totalPage: 0,
};

const {
  STOCK_SET_LIST,
  STOCK_SET_STATUS_LOADING,
  STOCK_SET_CURRENT_PAGE,
  STOCK_SET_TOTAL_PAGE,
  STOCK_SET_FILTER,
} = StockType;

const stock = (
  state: StockState = initialState,
  action: StockAction
): StockState => {
  const { type } = action;

  const setStockList = (): StockState => {
    const { payload: stocks } = action as StockSetList;
    return {
      ...state,
      stockList: stocks,
    };
  };

  const setStockStatusLoading = (): StockState => {
    const { payload: statusLoading } = action as StockSetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setStockCurrentPage = (): StockState => {
    const { payload: currentPage } = action as StockSetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setStockTotalPage = (): StockState => {
    const { payload: totalPage } = action as StockSetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  const setStockFilter = (): StockState => {
    const { payload: filterStock } = action as StockSetFilter;
    return {
      ...state,
      filterStock,
    };
  };

  switch (type) {
    case STOCK_SET_LIST:
      return setStockList();
    case STOCK_SET_STATUS_LOADING:
      return setStockStatusLoading();
    case STOCK_SET_CURRENT_PAGE:
      return setStockCurrentPage();
    case STOCK_SET_TOTAL_PAGE:
      return setStockTotalPage();
    case STOCK_SET_FILTER:
      return setStockFilter();
    default:
      return state;
  }
};

export default stock;
