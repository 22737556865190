import { E_FETCH_STATUS } from "../../constant";
import { ColourData, ColourFilterType } from "../../interfaces/colour";

export enum ColourType {
  COLOUR_SET_LIST = "COLOUR_SET_LIST",
  COLOUR_SET_FILTER = "COLOUR_SET_FILTER",
  COLOUR_SET_STATUS_LOADING = "COLOUR_SET_STATUS_LOADING",
  COLOUR_SET_CURRENT_PAGE = "COLOUR_SET_CURRENT_PAGE",
  COLOUR_SET_TOTAL_PAGE = "COLOUR_SET_TOTAL_PAGE",
}

export interface ColourSetList {
  type: ColourType.COLOUR_SET_LIST;
  payload: ColourData[];
}

export interface ColourSetFilter {
  type: ColourType.COLOUR_SET_FILTER;
  payload: ColourFilterType;
}

export interface ColourSetStatusLoading {
  type: ColourType.COLOUR_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface ColourSetCurrentPage {
  type: ColourType.COLOUR_SET_CURRENT_PAGE;
  payload: number;
}

export interface ColourSetTotalPage {
  type: ColourType.COLOUR_SET_TOTAL_PAGE;
  payload: number;
}

export type ColourAction =
  | ColourSetList
  | ColourSetFilter
  | ColourSetStatusLoading
  | ColourSetCurrentPage
  | ColourSetTotalPage;
