import { API_URL, BASE_URL } from "../constant";
import { DefaultUpdateResponse } from "../interfaces";
import { AuthResponse, CheckPasswordResponse } from "../interfaces/auth";
import { getCookie } from "../utils/cookies";

class AuthService {
  public static async doSignIn(body: FormData): Promise<AuthResponse> {
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.signIn}`, {
      method: "POST",
      body: body,
    });

    const response: AuthResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async checkPassword(body: FormData): Promise<number> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.checkPassword}`, {
      method: "POST",
      body: body,
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
    });

    const response: CheckPasswordResponse = await fetchResponse.json();
    return response.status;
  }

  public static async changePassword(
    body: FormData
  ): Promise<DefaultUpdateResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.changePassword}`, {
      method: "POST",
      body: body,
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
    });

    const response: DefaultUpdateResponse = await fetchResponse.json();
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Error Changing Password");
  }
}

export default AuthService;
