import { E_FETCH_STATUS } from "../../constant";
import {
  E_FILTER_STATUS,
  SalesReportData,
  SalesReportFilterType,
} from "../../interfaces/salesreport";
import {
  SalesReportAction,
  SalesReportSetCurrentPage,
  SalesReportSetFilter,
  SalesReportSetList,
  SalesReportSetStatusLoading,
  SalesReportSetTotalPage,
  SalesReportType,
} from "../types/salesreport";

interface SalesReportState {
  salesReportList: SalesReportData[];
  statusLoading: E_FETCH_STATUS;
  filterSalesReport: SalesReportFilterType;
  currentPage: number;
  totalPage: number;
}

const dateObj = new Date();
const currentYear = dateObj.getFullYear();
const currentMonth = dateObj.getMonth() + 1;

const initialState: SalesReportState = {
  salesReportList: [],
  statusLoading: E_FETCH_STATUS.INITIATE,
  filterSalesReport: {
    filterYear: currentYear,
    filterMonth: currentMonth,
    filterCreatedBy: {
      value: 0,
      label: "All Sales",
    },
    filterInvoiceNumber: "",
    filterSalesReportNo: "",
    filterStatusSalesReport: E_FILTER_STATUS.ALL,
  },
  currentPage: 1,
  totalPage: 0,
};

const {
  SALESREPORT_SET_LIST,
  SALESREPORT_SET_STATUS_LOADING,
  SALESREPORT_SET_CURRENT_PAGE,
  SALESREPORT_SET_FILTER,
  SALESREPORT_SET_TOTAL_PAGE,
} = SalesReportType;

const salesreport = (
  state: SalesReportState = initialState,
  action: SalesReportAction
): SalesReportState => {
  const { type } = action;

  const setSalesReportList = (): SalesReportState => {
    const { payload: salesReports } = action as SalesReportSetList;
    return {
      ...state,
      salesReportList: salesReports,
    };
  };

  const setSalesReportStatusLoading = (): SalesReportState => {
    const { payload: statusLoading } = action as SalesReportSetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setSalesReportCurrentPage = (): SalesReportState => {
    const { payload: currentPage } = action as SalesReportSetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setSalesReportTotalPage = (): SalesReportState => {
    const { payload: totalPage } = action as SalesReportSetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  const setSalesReportFilter = (): SalesReportState => {
    const { payload: filterSalesReport } = action as SalesReportSetFilter;
    return {
      ...state,
      filterSalesReport,
    };
  };

  switch (type) {
    case SALESREPORT_SET_LIST:
      return setSalesReportList();
    case SALESREPORT_SET_STATUS_LOADING:
      return setSalesReportStatusLoading();
    case SALESREPORT_SET_CURRENT_PAGE:
      return setSalesReportCurrentPage();
    case SALESREPORT_SET_TOTAL_PAGE:
      return setSalesReportTotalPage();
    case SALESREPORT_SET_FILTER:
      return setSalesReportFilter();
    default:
      return state;
  }
};

export default salesreport;
