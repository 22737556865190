import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { ComissionCGSData } from "../../interfaces/comission";
import {
  ComissionCGSAction,
  ComissionCGSSetCurrentPage,
  ComissionCGSSetFilter,
  ComissionCGSSetList,
  ComissionCGSSetStatusLoading,
  ComissionCGSSetTotalPage,
  ComissionCGSType,
} from "../types/comissionCGS";

interface ComissionCGSState {
  filterComissionCGS: DefaultSelection;
  statusLoading: E_FETCH_STATUS;
  comissionCGSList: ComissionCGSData[];
  currentPage: number;
  totalPage: number;
}

const initialState: ComissionCGSState = {
  filterComissionCGS: { label: "All Shop", value: 0 },
  statusLoading: E_FETCH_STATUS.INITIATE,
  comissionCGSList: [],
  currentPage: 1,
  totalPage: 0,
};

const {
  COMISSION_CGS_SET_LIST,
  COMISSION_CGS_SET_STATUS_LOADING,
  COMISSION_CGS_SET_FILTER,
  COMISSION_CGS_SET_CURRENT_PAGE,
  COMISSION_CGS_SET_TOTAL_PAGE,
} = ComissionCGSType;

const comissionCGS = (
  state: ComissionCGSState = initialState,
  action: ComissionCGSAction
): ComissionCGSState => {
  const { type } = action;

  const setComissionCGSList = (): ComissionCGSState => {
    const { payload: comissionCGSList } = action as ComissionCGSSetList;
    return {
      ...state,
      comissionCGSList,
    };
  };

  const setComissionCGSStatusLoading = (): ComissionCGSState => {
    const { payload: statusLoading } = action as ComissionCGSSetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setComissionCGSFilter = (): ComissionCGSState => {
    const { payload: filterComissionCGS } = action as ComissionCGSSetFilter;
    return {
      ...state,
      filterComissionCGS,
    };
  };

  const setComissionCGSCurrentPage = (): ComissionCGSState => {
    const { payload: currentPage } = action as ComissionCGSSetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setComissionCGSTotalPage = (): ComissionCGSState => {
    const { payload: totalPage } = action as ComissionCGSSetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  switch (type) {
    case COMISSION_CGS_SET_LIST:
      return setComissionCGSList();
    case COMISSION_CGS_SET_STATUS_LOADING:
      return setComissionCGSStatusLoading();
    case COMISSION_CGS_SET_FILTER:
      return setComissionCGSFilter();
    case COMISSION_CGS_SET_CURRENT_PAGE:
      return setComissionCGSCurrentPage();
    case COMISSION_CGS_SET_TOTAL_PAGE:
      return setComissionCGSTotalPage();
    default:
      return state;
  }
};

export default comissionCGS;
