import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { BrandData } from "../../interfaces/brand";
import {
  BrandAction,
  BrandSetFilter,
  BrandSetList,
  BrandSetStatusLoading,
  BrandType,
} from "../types/brand";

interface BrandState {
  filterBrand: DefaultSelection;
  statusLoading: E_FETCH_STATUS;
  brandList: BrandData[];
}

const initialState: BrandState = {
  filterBrand: {
    label: "All Brand",
    value: 0,
  },
  statusLoading: E_FETCH_STATUS.INITIATE,
  brandList: [],
};

const { BRAND_SET_LIST, BRAND_SET_STATUS_LOADING, BRAND_SET_FILTER } =
  BrandType;

const brand = (
  state: BrandState = initialState,
  action: BrandAction
): BrandState => {
  const { type } = action;

  const setBrandList = (): BrandState => {
    const { payload: brandList } = action as BrandSetList;
    return {
      ...state,
      brandList,
    };
  };

  const setBrandStatusLoading = (): BrandState => {
    const { payload: statusLoading } = action as BrandSetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setBrandFilter = (): BrandState => {
    const { payload: filterBrand } = action as BrandSetFilter;
    return {
      ...state,
      filterBrand,
    };
  };

  switch (type) {
    case BRAND_SET_LIST:
      return setBrandList();
    case BRAND_SET_STATUS_LOADING:
      return setBrandStatusLoading();
    case BRAND_SET_FILTER:
      return setBrandFilter();
    default:
      return state;
  }
};

export default brand;
