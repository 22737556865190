import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { reducers } from "./reducers";

const composeEnhancers = composeWithDevTools({
  name: "BlancoSalesReport",
});

export const stores = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
