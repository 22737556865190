import { ZipCodeData } from "../../interfaces/util";
import {
  DaerahAction,
  DaerahSetKabupaten,
  DaerahSetKecamatan,
  DaerahSetKelurahan,
  DaerahSetProvinsi,
  DaerahSetZipcode,
  DaerahType,
} from "../types/daerah";

interface DaerahState {
  provinsi: string[];
  kabupaten: string[];
  kelurahan: string[];
  kecamatan: string[];
  zipcode: ZipCodeData;
}

const initialState: DaerahState = {
  provinsi: [],
  kabupaten: [],
  kelurahan: [],
  kecamatan: [],
  zipcode: {
    id: 0,
    kodePos: "",
  },
};

const {
  DAERAH_SET_PROVINSI,
  DAERAH_SET_KABUPATEN,
  DAERAH_SET_KECAMATAN,
  DAERAH_SET_KELURAHAN,
  DAERAH_SET_ZIPCODE,
} = DaerahType;

const user = (
  state: DaerahState = initialState,
  action: DaerahAction
): DaerahState => {
  const { type } = action;

  const setProvinsi = (): DaerahState => {
    const { payload: provinsi } = action as DaerahSetProvinsi;
    return {
      ...state,
      provinsi,
    };
  };

  const setKabupaten = (): DaerahState => {
    const { payload: kabupaten } = action as DaerahSetKabupaten;
    return {
      ...state,
      kabupaten,
    };
  };

  const setKecamatan = (): DaerahState => {
    const { payload: kecamatan } = action as DaerahSetKecamatan;
    return {
      ...state,
      kecamatan,
    };
  };

  const setKelurahan = (): DaerahState => {
    const { payload: kelurahan } = action as DaerahSetKelurahan;
    return {
      ...state,
      kelurahan,
    };
  };

  const setZipcode = (): DaerahState => {
    const { payload: zipcode } = action as DaerahSetZipcode;
    return {
      ...state,
      zipcode,
    };
  };

  switch (type) {
    case DAERAH_SET_PROVINSI:
      return setProvinsi();
    case DAERAH_SET_KABUPATEN:
      return setKabupaten();
    case DAERAH_SET_KELURAHAN:
      return setKelurahan();
    case DAERAH_SET_KECAMATAN:
      return setKecamatan();
    case DAERAH_SET_ZIPCODE:
      return setZipcode();
    default:
      return state;
  }
};

export default user;
