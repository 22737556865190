import { E_FETCH_STATUS } from "../../constant";
import { ComissionFilterData } from "../../interfaces/comission";
import { SalesTargetData } from "../../interfaces/salestarget";
import {
  ComissionSPGAction,
  ComissionSPGSetCurrentPage,
  ComissionSPGSetFilter,
  ComissionSPGSetList,
  ComissionSPGSetStatusLoading,
  ComissionSPGSetTotalPage,
  ComissionSPGType,
} from "../types/comissionSPG";

interface ComissionSPGState {
  filterComissionSPG: ComissionFilterData;
  statusLoading: E_FETCH_STATUS;
  comissionSPGList: SalesTargetData[];
  currentPage: number;
  totalPage: number;
}

const initialState: ComissionSPGState = {
  filterComissionSPG: {
    filterBrand: 0,
    filterUser: {
      label: "All User",
      value: 0,
    },
  },
  statusLoading: E_FETCH_STATUS.INITIATE,
  comissionSPGList: [],
  currentPage: 1,
  totalPage: 0,
};

const {
  COMISSION_SPG_SET_LIST,
  COMISSION_SPG_SET_STATUS_LOADING,
  COMISSION_SPG_SET_FILTER,
  COMISSION_SPG_SET_CURRENT_PAGE,
  COMISSION_SPG_SET_TOTAL_PAGE,
} = ComissionSPGType;

const comissionSPG = (
  state: ComissionSPGState = initialState,
  action: ComissionSPGAction
): ComissionSPGState => {
  const { type } = action;

  const setComissionSPGList = (): ComissionSPGState => {
    const { payload: comissionSPGList } = action as ComissionSPGSetList;
    return {
      ...state,
      comissionSPGList,
    };
  };

  const setComissionSPGStatusLoading = (): ComissionSPGState => {
    const { payload: statusLoading } = action as ComissionSPGSetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setComissionSPGFilter = (): ComissionSPGState => {
    const { payload: filterComissionSPG } = action as ComissionSPGSetFilter;
    return {
      ...state,
      filterComissionSPG,
    };
  };

  const setComissionSPGCurrentPage = (): ComissionSPGState => {
    const { payload: currentPage } = action as ComissionSPGSetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setComissionSPGTotalPage = (): ComissionSPGState => {
    const { payload: totalPage } = action as ComissionSPGSetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  switch (type) {
    case COMISSION_SPG_SET_LIST:
      return setComissionSPGList();
    case COMISSION_SPG_SET_STATUS_LOADING:
      return setComissionSPGStatusLoading();
    case COMISSION_SPG_SET_FILTER:
      return setComissionSPGFilter();
    case COMISSION_SPG_SET_CURRENT_PAGE:
      return setComissionSPGCurrentPage();
    case COMISSION_SPG_SET_TOTAL_PAGE:
      return setComissionSPGTotalPage();
    default:
      return state;
  }
};

export default comissionSPG;
