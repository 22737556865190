import { E_FETCH_STATUS } from "../../constant";
import { FilterStockInterface, StockInputData } from "../../interfaces/stock";

export enum StockInputType {
  STOCK_INPUT_SET_LIST = "STOCK_INPUT_SET_LIST",
  STOCK_INPUT_SET_STATUS_LOADING = "STOCK_INPUT_SET_STATUS_LOADING",
  STOCK_INPUT_SET_CURRENT_PAGE = "STOCK_INPUT_SET_CURRENT_PAGE",
  STOCK_INPUT_SET_TOTAL_PAGE = "STOCK_INPUT_SET_TOTAL_PAGE",
  STOCK_INPUT_SET_FILTER = "STOCK_INPUT_SET_FILTER",
}

export interface StockInputSetList {
  type: StockInputType.STOCK_INPUT_SET_LIST;
  payload: StockInputData[];
}

export interface StockInputSetStatusLoading {
  type: StockInputType.STOCK_INPUT_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface StockInputSetCurrentPage {
  type: StockInputType.STOCK_INPUT_SET_CURRENT_PAGE;
  payload: number;
}

export interface StockInputSetTotalPage {
  type: StockInputType.STOCK_INPUT_SET_TOTAL_PAGE;
  payload: number;
}

export interface StockInputSetFilter {
  type: StockInputType.STOCK_INPUT_SET_FILTER;
  payload: FilterStockInterface;
}

export type StockInputAction =
  | StockInputSetList
  | StockInputSetStatusLoading
  | StockInputSetCurrentPage
  | StockInputSetTotalPage
  | StockInputSetFilter;
