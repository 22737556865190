import { E_FETCH_STATUS } from "../../constant";
import { CategoryData, CategoryFilterType } from "../../interfaces/categories";
import {
  CategoryAction,
  CategorySetCurrentPage,
  CategorySetFilter,
  CategorySetList,
  CategorySetStatusLoading,
  CategorySetTotalPage,
  CategoryType,
} from "../types/category";

interface CategoryState {
  categoriesList: CategoryData[];
  statusLoading: E_FETCH_STATUS;
  filterCategories: CategoryFilterType;
  currentPage: number;
  totalPage: number;
}

const initialState: CategoryState = {
  categoriesList: [],
  statusLoading: E_FETCH_STATUS.INITIATE,
  filterCategories: {
    filterBrand: {
      label: "All Brand",
      value: 0,
    },
    filterCategory: {
      label: "All Category",
      value: 0,
    },
  },
  currentPage: 1,
  totalPage: 0,
};

const {
  CATEGORY_SET_LIST,
  CATEGORY_SET_FILTER,
  CATEGORY_SET_STATUS_LOADING,
  CATEGORY_SET_CURRENT_PAGE,
  CATEGORY_SET_TOTAL_PAGE,
} = CategoryType;

const category = (
  state: CategoryState = initialState,
  action: CategoryAction
): CategoryState => {
  const { type } = action;

  const setCategoryList = (): CategoryState => {
    const { payload: categories } = action as CategorySetList;
    return {
      ...state,
      categoriesList: categories,
    };
  };

  const setCategoryFilter = (): CategoryState => {
    const { payload: filterCategories } = action as CategorySetFilter;
    return {
      ...state,
      filterCategories: filterCategories,
    };
  };

  const setCategoryStatusLoading = (): CategoryState => {
    const { payload: statusLoading } = action as CategorySetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setCategoryCurrentPage = (): CategoryState => {
    const { payload: currentPage } = action as CategorySetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setCategoryTotalPage = (): CategoryState => {
    const { payload: totalPage } = action as CategorySetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  switch (type) {
    case CATEGORY_SET_LIST:
      return setCategoryList();
    case CATEGORY_SET_STATUS_LOADING:
      return setCategoryStatusLoading();
    case CATEGORY_SET_CURRENT_PAGE:
      return setCategoryCurrentPage();
    case CATEGORY_SET_TOTAL_PAGE:
      return setCategoryTotalPage();
    case CATEGORY_SET_FILTER:
      return setCategoryFilter();
    default:
      return state;
  }
};

export default category;
