import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import shop from "./shop";
import daerah from "./daerah";
import product from "./product";
import category from "./category";
import packages from "./packages";
import colour from "./colour";
import salesreport from "./salesreport";
import brand from "./brand";
import stock from "./stock";
import stockInput from "./stockInput";
import finishing from "./finishing";
import comissionSPG from "./comissionSPG";
import comissionCategory from "./comissionCategory";
import comissionCGS from "./comissionCGS";
import excludeSingleComission from "./excludeSingleComission";

export const reducers = combineReducers({
  auth,
  user,
  shop,
  daerah,
  product,
  category,
  packages,
  colour,
  salesreport,
  brand,
  stock,
  stockInput,
  finishing,
  comissionSPG,
  comissionCategory,
  comissionCGS,
  excludeSingleComission,
});

export type AppStateType = ReturnType<typeof reducers>;
