import React, { FC, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
// import AuthService from "../../services/Auth";
import { setCookie } from "../../utils/cookies";
import "./index.scss";
import { DivContent } from "./style";
import bgBlanco from "../../assets/bg-sign-in.jpg";
import AuthService from "../../services/Auth";

const SignIn: FC = () => {
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const changeUserName = (e: any) => {
    setUserName(e.target.value);
  };

  const changePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const signIn = () => {
    const body = new FormData();
    body.append("username", userName);
    body.append("password", password);
    AuthService.doSignIn(body)
      .then((user) => {
        if (user.data) {
          setCookie("user", user.data.username, 1 / 8);
          setCookie("userstatus", user.data.status.toString(), 1 / 8);
          setCookie("currentUser", user.data.nameToBeShown, 1 / 8);
          setCookie("whatZitToYa", user.data.whatzittoya, 1 / 8);
          setCookie("userId", user.data.id, 1 / 8);
          setCookie(
            "username",
            `${user.data.firstName} ${user.data.lastName}`,
            1 / 8
          );
          window.location.href = "/dashboard";
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  return (
    <>
      <DivContent
        style={{
          height: "100vh",
          backgroundImage: `url(${bgBlanco})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Row>
            <Col className="text-center primaryColor">
              <h3>Tranindo Sales Report App</h3>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={6} sm={12}>
              <Form className="form-sign-in">
                <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    value={userName}
                    onChange={(e) => changeUserName(e)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => changePassword(e)}
                  />
                </Form.Group>
                <div className="d-grid gap-2">
                  <Button
                    size="lg"
                    className="btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      signIn();
                    }}
                    type="submit"
                  >
                    Login
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </DivContent>
    </>
  );
};

export default SignIn;
