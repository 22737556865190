import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { FinishingData } from "../../interfaces/finishing";
import {
  FinishingAction,
  FinishingSetFilter,
  FinishingSetList,
  FinishingSetStatusLoading,
  FinishingType,
} from "../types/finishing";

interface FinishingState {
  filterFinishing: DefaultSelection;
  statusLoading: E_FETCH_STATUS;
  finishingList: FinishingData[];
}

const initialState: FinishingState = {
  filterFinishing: {
    label: "All Finishing",
    value: 0,
  },
  statusLoading: E_FETCH_STATUS.INITIATE,
  finishingList: [],
};

const {
  FINISHING_SET_LIST,
  FINISHING_SET_STATUS_LOADING,
  FINISHING_SET_FILTER,
} = FinishingType;

const finishing = (
  state: FinishingState = initialState,
  action: FinishingAction
): FinishingState => {
  const { type } = action;

  const setFinishingList = (): FinishingState => {
    const { payload: finishingList } = action as FinishingSetList;
    return {
      ...state,
      finishingList,
    };
  };

  const setFinishingStatusLoading = (): FinishingState => {
    const { payload: statusLoading } = action as FinishingSetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setFinishingFilter = (): FinishingState => {
    const { payload: filterFinishing } = action as FinishingSetFilter;
    return {
      ...state,
      filterFinishing,
    };
  };

  switch (type) {
    case FINISHING_SET_LIST:
      return setFinishingList();
    case FINISHING_SET_STATUS_LOADING:
      return setFinishingStatusLoading();
    case FINISHING_SET_FILTER:
      return setFinishingFilter();
    default:
      return state;
  }
};

export default finishing;
