import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { ExcludeSingleComissionData } from "../../interfaces/excludeSingleComission";

export enum ExcludeSingleComissionType {
  EXCLUDE_SINGLE_COMISSION_SET_LIST = "EXCLUDE_SINGLE_COMISSION_SET_LIST",
  EXCLUDE_SINGLE_COMISSION_SET_STATUS_LOADING = "EXCLUDE_SINGLE_COMISSION_SET_STATUS_LOADING",
  EXCLUDE_SINGLE_COMISSION_SET_FILTER = "EXCLUDE_SINGLE_COMISSION_SET_FILTER",
  EXCLUDE_SINGLE_COMISSION_SET_CURRENT_PAGE = "EXCLUDE_SINGLE_COMISSION_SET_CURRENT_PAGE",
  EXCLUDE_SINGLE_COMISSION_SET_TOTAL_PAGE = " EXCLUDE_SINGLE_COMISSION_SET_TOTAL_PAGE",
}

export interface ExcludeSingleComissionSetList {
  type: ExcludeSingleComissionType.EXCLUDE_SINGLE_COMISSION_SET_LIST;
  payload: ExcludeSingleComissionData[];
}

export interface ExcludeSingleComissionSetStatusLoading {
  type: ExcludeSingleComissionType.EXCLUDE_SINGLE_COMISSION_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface ExcludeSingleComissionSetFilter {
  type: ExcludeSingleComissionType.EXCLUDE_SINGLE_COMISSION_SET_FILTER;
  payload: DefaultSelection;
}

export interface ExcludeSingleComissionSetCurrentPage {
  type: ExcludeSingleComissionType.EXCLUDE_SINGLE_COMISSION_SET_CURRENT_PAGE;
  payload: number;
}

export interface ExcludeSingleComissionSetTotalPage {
  type: ExcludeSingleComissionType.EXCLUDE_SINGLE_COMISSION_SET_TOTAL_PAGE;
  payload: number;
}

export type ExcludeSingleComissionAction =
  | ExcludeSingleComissionSetList
  | ExcludeSingleComissionSetStatusLoading
  | ExcludeSingleComissionSetFilter
  | ExcludeSingleComissionSetCurrentPage
  | ExcludeSingleComissionSetTotalPage;
