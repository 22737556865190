import { E_FETCH_STATUS } from "../../constant";
import { FilterProductInterface, ProductData } from "../../interfaces/product";

export enum ProductType {
  PRODUCT_SET_LIST = "PRODUCT_SET_LIST",
  PRODUCT_SET_STATUS_LOADING = "PRODUCT_SET_STATUS_LOADING",
  PRODUCT_SET_CURRENT_PAGE = "PRODUCT_SET_CURRENT_PAGE",
  PRODUCT_SET_TOTAL_PAGE = "PRODUCT_SET_TOTAL_PAGE",
  PRODUCT_SET_FILTER = "PRODUCT_SET_FILTER",
}

export interface ProductSetList {
  type: ProductType.PRODUCT_SET_LIST;
  payload: ProductData[];
}

export interface ProductSetStatusLoading {
  type: ProductType.PRODUCT_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface ProductSetCurrentPage {
  type: ProductType.PRODUCT_SET_CURRENT_PAGE;
  payload: number;
}

export interface ProductSetTotalPage {
  type: ProductType.PRODUCT_SET_TOTAL_PAGE;
  payload: number;
}

export interface ProductSetFilter {
  type: ProductType.PRODUCT_SET_FILTER;
  payload: FilterProductInterface;
}

export type ProductAction =
  | ProductSetList
  | ProductSetStatusLoading
  | ProductSetCurrentPage
  | ProductSetTotalPage
  | ProductSetFilter;
