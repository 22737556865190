import { E_FETCH_STATUS } from "../../constant";
import {
  SalesReportData,
  SalesReportFilterType,
} from "../../interfaces/salesreport";

export enum SalesReportType {
  SALESREPORT_SET_LIST = "SALESREPORT_SET_LIST",
  SALESREPORT_SET_STATUS_LOADING = "SALESREPORT_SET_STATUS_LOADING",
  SALESREPORT_SET_CURRENT_PAGE = "SALESREPORT_SET_CURRENT_PAGE",
  SALESREPORT_SET_TOTAL_PAGE = "SALESREPORT_SET_TOTAL_PAGE",
  SALESREPORT_SET_FILTER = "SALESREPORT_SET_FILTER",
}

export interface SalesReportSetList {
  type: SalesReportType.SALESREPORT_SET_LIST;
  payload: SalesReportData[];
}

export interface SalesReportSetStatusLoading {
  type: SalesReportType.SALESREPORT_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface SalesReportSetCurrentPage {
  type: SalesReportType.SALESREPORT_SET_CURRENT_PAGE;
  payload: number;
}

export interface SalesReportSetTotalPage {
  type: SalesReportType.SALESREPORT_SET_TOTAL_PAGE;
  payload: number;
}

export interface SalesReportSetFilter {
  type: SalesReportType.SALESREPORT_SET_FILTER;
  payload: SalesReportFilterType;
}

export type SalesReportAction =
  | SalesReportSetList
  | SalesReportSetStatusLoading
  | SalesReportSetCurrentPage
  | SalesReportSetTotalPage
  | SalesReportSetFilter;
