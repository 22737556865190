import { E_AUTH_STATUS } from "../../constant";
import { AuthAction, AuthSetData, AuthType } from "../types/auth";

interface AuthState {
  username: string;
  status: E_AUTH_STATUS;
}

const initialState: AuthState = {
  username: "",
  status: E_AUTH_STATUS.INIT,
};

const { AUTH_SET_DATA } = AuthType;

const auth = (
  state: AuthState = initialState,
  action: AuthAction
): AuthState => {
  const { type } = action;

  const setAuthData = (): AuthState => {
    const {
      payload: { username, status },
    } = action as AuthSetData;
    return {
      ...state,
      username,
      status,
    };
  };

  switch (type) {
    case AUTH_SET_DATA:
      return setAuthData();
    default:
      return state;
  }
};

export default auth;
