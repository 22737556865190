import { E_FETCH_STATUS } from "../../constant";
import { FilterStockInterface, StockData } from "../../interfaces/stock";

export enum StockType {
  STOCK_SET_LIST = "STOCK_SET_LIST",
  STOCK_SET_STATUS_LOADING = "STOCK_SET_STATUS_LOADING",
  STOCK_SET_CURRENT_PAGE = "STOCK_SET_CURRENT_PAGE",
  STOCK_SET_TOTAL_PAGE = "STOCK_SET_TOTAL_PAGE",
  STOCK_SET_FILTER = "STOCK_SET_FILTER",
}

export interface StockSetList {
  type: StockType.STOCK_SET_LIST;
  payload: StockData[];
}

export interface StockSetStatusLoading {
  type: StockType.STOCK_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface StockSetCurrentPage {
  type: StockType.STOCK_SET_CURRENT_PAGE;
  payload: number;
}

export interface StockSetTotalPage {
  type: StockType.STOCK_SET_TOTAL_PAGE;
  payload: number;
}

export interface StockSetFilter {
  type: StockType.STOCK_SET_FILTER;
  payload: FilterStockInterface;
}

export type StockAction =
  | StockSetList
  | StockSetStatusLoading
  | StockSetCurrentPage
  | StockSetTotalPage
  | StockSetFilter;
