import { E_FETCH_STATUS } from "../../constant";
import { ComissionFilterData } from "../../interfaces/comission";
import { SalesTargetData } from "../../interfaces/salestarget";

export enum ComissionSPGType {
  COMISSION_SPG_SET_LIST = "COMISSION_SPG_SET_LIST",
  COMISSION_SPG_SET_STATUS_LOADING = "COMISSION_SPG_SET_STATUS_LOADING",
  COMISSION_SPG_SET_FILTER = "COMISSION_SPG_SET_FILTER",
  COMISSION_SPG_SET_CURRENT_PAGE = "COMISSION_SPG_SET_CURRENT_PAGE",
  COMISSION_SPG_SET_TOTAL_PAGE = " COMISSION_SPG_SET_TOTAL_PAGE",
}

export interface ComissionSPGSetList {
  type: ComissionSPGType.COMISSION_SPG_SET_LIST;
  payload: SalesTargetData[];
}

export interface ComissionSPGSetStatusLoading {
  type: ComissionSPGType.COMISSION_SPG_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface ComissionSPGSetFilter {
  type: ComissionSPGType.COMISSION_SPG_SET_FILTER;
  payload: ComissionFilterData;
}

export interface ComissionSPGSetCurrentPage {
  type: ComissionSPGType.COMISSION_SPG_SET_CURRENT_PAGE;
  payload: number;
}

export interface ComissionSPGSetTotalPage {
  type: ComissionSPGType.COMISSION_SPG_SET_TOTAL_PAGE;
  payload: number;
}

export type ComissionSPGAction =
  | ComissionSPGSetList
  | ComissionSPGSetStatusLoading
  | ComissionSPGSetFilter
  | ComissionSPGSetCurrentPage
  | ComissionSPGSetTotalPage;
